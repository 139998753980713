export default [{
    header: 'Proveedores',
    icon: 'BriefcaseIcon',
    children: [
        {
            title: 'Lista',
            route: 'providers-list',
            icon: 'ListIcon',
            action: 'read',
		    resource: 'providers'
        },
        {
            title: 'Nuevo',
            route: 'providers-create',
            icon: 'PlusSquareIcon',
            action: 'create',
		    resource: 'providers'
        }
    ]
}];