export default [{
    header: 'Evaluaciones',
    icon: 'BookmarkIcon',
    children: [
        {
            title: 'Criticidad',
            route: 'criticalities-list',
            icon: 'ZapIcon',
            action: 'read',
		    resource: 'criticalities'
        },
        {
            title: 'Desempeño',
            route: 'performances-list',
            icon: 'TrendingUpIcon',
            action: 'read',
		    resource: 'performances'
        },
    ]
}];