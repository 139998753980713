export default [{
    header: 'Reporteria',
    icon: 'PieChartIcon',
    children: [
		{
			title: 'Evaluaciones de Criticidad',
			route: 'report-criticality-evaluations',
			icon: 'ListIcon'
		}, 
		{
			title: 'Evaluaciones de Desempeño',
			route: 'report-performance-evaluations',
			icon: 'ListIcon'
		}
	]
}]