import home from './home';
import providers from './providers';
import contracts from './contracts';
import evaluations from './evaluations';
import documents from './documents';
import reporters from './reporters';

export default [
    ...home,
	...providers,
	...contracts,
	...evaluations,
	...documents,
	...reporters
];