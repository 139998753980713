export default [{
    header: 'Documentos',
    icon: 'ArchiveIcon',
    children: [
        {
            title: 'Lista',
            route: 'documents-list',
            icon: 'ListIcon',
            action: 'read',
		    resource: 'documents'
        },
    ]
}];